import { useMediaQuery } from '@kaliber/use-media-query'

import { useTranslate } from '/machinery/I18n'

import { ContainerXl, ContainerLg } from '/features/buildingBlocks/Container'
import { HeadingCondensedMd, HeadingCondensedLg, HeadingCondensedXxl } from '/features/buildingBlocks/Heading'
import { ButtonLinkRed } from '/features/buildingBlocks/Button'
import MetaTagsCentered from '/features/buildingBlocks/MetaTagsCentered.universal'
import { ImageCover } from '/features/buildingBlocks/Image'

import mediaStyles from '/cssGlobal/media.css'
import styles from './Hero.css'

export function HeroSimpleLg({ title, subtitle = undefined }) {
  return <HeroSimpleBase HeadingComponent={HeadingCondensedLg} className={styles.componentSimpleLg} {...{ title, subtitle }} />
}

export function HeroSimpleXxl({ title, subtitle = undefined }) {
  return <HeroSimpleBase HeadingComponent={HeadingCondensedXxl} className={styles.componentSimpleXxl} {...{ title, subtitle }} />
}

function HeroSimpleBase({ title, HeadingComponent, subtitle, className }) {
  return (
    <section data-x='hero' className={cx(styles.componentSimpleBase, className)}>
      <ContainerLg>
        <HeadingAndTags {...{ title, HeadingComponent, subtitle }} />
      </ContainerLg>
    </section>
  )
}

export function HeroMetaMd({ title, tags, subtitle }) {
  return <HeroMetaBase HeadingComponent={HeadingCondensedMd} className={styles.componentMetaMd} {...{ title, tags, subtitle }} />
}

export function HeroMetaLg({ title, tags, label }) {
  return <HeroMetaBase HeadingComponent={HeadingCondensedLg} className={styles.componentMetaLg} {...{ title, tags, label }} />
}

function HeroMetaBase({ title, tags, HeadingComponent, subtitle = undefined, label = undefined, className }) {
  return (
    <section data-x='hero' className={cx(styles.componentMetaBase, className)}>
      <StickyContainer layoutClassName={styles.stickyContainerLayout}>
        <ContainerLg>
          <HeadingAndTags {...{ title, tags, HeadingComponent, subtitle, label }} />
        </ContainerLg>
      </StickyContainer>
    </section>
  )
}

function StickyContainer({ children, layoutClassName }) {
  return (
    <div className={cx(styles.componentStickyContainer, layoutClassName)}>
      {children}
    </div>
  )
}

export function HeroCta({ title, link = undefined, subtitle = undefined, image = undefined, layoutClassName = undefined }) {
  return (image?.asset
    ? <HeroCtaWithImage {...{ title, link, subtitle, image, layoutClassName }} />
    : <HeroCtaBase className={layoutClassName} {...{ title, link, subtitle }} />
  )
}

function HeroCtaWithImage({ title, link, subtitle, image, layoutClassName }) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)

  return (
    <HeroCtaBase
      sectionContent={image?.asset && (
        <ImageCover
          sizes='100vw'
          aspectRatio={isViewportMd ? 16 / 9 : 9 / 16}
          layoutClassName={styles.imageLayout}
          {...{ image }}
        />
      )}
      className={cx(styles.componentCtaWithImage, layoutClassName)}
      {...{ title, link, subtitle, image }}
    />
  )
}

function HeroCtaBase({ title, link, subtitle, sectionContent = undefined, className = undefined }) {
  const { __ } = useTranslate()

  return (
    <section data-x='hero' className={cx(styles.componentCtaBase, className)}>
      <ContainerXl layoutClassName={styles.contentWrapperLayout}>
        <div className={styles.container}>
          {subtitle && <small className={styles.subtitle}>{subtitle}</small>}

          {title && <HeadingCondensedXxl h={1} {...{ title }} />}

          {link && (
            <ButtonLinkRed
              href={link}
              label={__`check-vacancies`}
              dataX='link-to-jobs'
              layoutClassName={styles.linkLayout}
            />
          )}
        </div>
      </ContainerXl>

      {sectionContent}
    </section>
  )
}

function HeadingAndTags({ title, subtitle, HeadingComponent, tags = undefined, label = undefined }) {
  return (
    <div className={styles.componentHeadingAndTags}>
      {subtitle && <small className={styles.subtitle}>{subtitle}</small>}

      {HeadingComponent && (
        <HeadingComponent centered h={1} layoutClassName={styles.titleLayout} {...{ title }} />
      )}

      {Boolean(tags?.length) && <MetaTagsCentered {...{ tags, label }} />}
    </div>
  )
}
